// You can add global styles to this file, and also import other style files
@import 'global-styles';
@import 'web_colors';
@import 'web_ui_helpers';
@import 'web_layout';
@import 'shared_animations';
@import 'styles-app-loading'; // Invoice2go has different app loading design

body {
  width: 100%;

  &.billing {
    min-width: 100%;
    min-height: 100%;
  }

  &.vex-open {
    overflow: inherit;
  }
}

input,
textarea {
  // Chrome/Opera/Safari
  &::-webkit-input-placeholder {
    font-weight: bold !important;
  }

  // Firefox 19+
  &::-moz-placeholder {
    font-weight: bold !important;
  }

  // IE 10+
  &:-ms-input-placeholder {
    font-weight: bold !important;
  }

  // Firefox 18-
  &:-moz-placeholder {
    font-weight: bold !important;
  }
}

// remove spinner on number fields for webkit browsers
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.premium-icon {
  @include premium-icon;
}

.new-flag {
  @include font-size(12px);

  display: block;
  text-align: center;

  padding: 3px 5px;
  border-radius: 10px;
  box-sizing: border-box;
  min-width: 40px;
  margin-left: 10px;

  color: $text-reversed-color;
  background-color: $support-success-color;
}

.beta-badge {
  @include font-size(12px);

  display: block;
  text-align: center;

  padding: 3px;
  border-radius: 10px;
  box-sizing: border-box;
  min-width: 40px;
  height: 20px;

  color: $text-primary-color;
  background-color: $background-secondary-color;
}
