@import 'web_layout';
@import 'shared_animations';
@import 'web_colors';
@import 'shared_ui_helpers';

@mixin status() {
  display: block;

  font-size: 12px;
  line-height: ceil(12px * 1.2);
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 2px;
  padding: 3px 5px;
  box-sizing: border-box;
  max-width: 100%;
}

@mixin document-status($status) {
  @include status;

  @if ($status == 'sent') {
    color: $text-reversed-color;
    background-color: $storm-color;
  } @else if ($status == 'unsent') {
    color: $text-primary-color;
    background-color: $background-secondary-color;
  } @else if ($status == 'opened') {
    color: $text-reversed-color;
    background-color: $blue-color;
  } @else if ($status == 'fully-paid') {
    color: $text-reversed-color;
    background-color: $support-success-color;
  } @else if ($status == 'partially-paid') {
    color: $action-primary-color;
    background-color: $background-secondary-color;
  } @else if ($status == 'pending') {
    color: $action-primary-color;
    background-color: $background-secondary-color;
  } @else if ($status == 'paid') {
    color: $text-reversed-color;
    background-color: $blue-color;
  }
}

@mixin billed-status() {
  @include status;

  color: $support-success-color;
  background-color: $background-secondary-color;
}

@mixin premium-icon() {
  position: relative;
  top: 1px;
}

@mixin dropdown-action() {
  @include font-size(15px);

  padding: spacing(1) spacing(2);
  width: 100%;
  min-width: 130px;
  box-sizing: border-box;
  cursor: pointer;
  color: $text-primary-color;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  border-radius: 0;

  transition: all 0.2s ease-out;

  &:hover {
    background-color: $background-secondary-color;
  }
}

@mixin tabs-list {
  display: flex;
  align-items: flex-end;

  background-color: $background-secondary-color;

  flex-shrink: 0;
  padding: 0 $main-content-horizontal-spacing;
}

@mixin tabs {
  @include font-size(15px);

  min-width: 120px;
  height: $tab-height;

  line-height: 1.5em;

  ::ng-deep .router-link {
    $selection-indicator-height: 5px;

    color: $text-secondary-color;
    font-weight: bold;

    padding: spacing(1) spacing(3) ($selection-indicator-height + spacing(1));
    border-radius: 0;
    border-bottom: $selection-indicator-height solid transparent;

    &:hover {
      color: $text-secondary-color;
    }

    &.active {
      border-bottom-color: $support-brand-color;
      color: $text-primary-color;
    }
  }
}

@mixin tabbed-view(
  $tab-horizontal-spacing: false,
  $tab-min-width: 0,
  $center-tabs: false
) {
  $form-section-vertical-spacing: spacing(3);
  $tabbed-view-tab-padding: spacing(1);

  display: flex;
  flex-direction: column;
  width: 100%;

  .tabs {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @if ($tab-horizontal-spacing == false) {
      justify-content: space-between;
    }

    @if ($center-tabs == false) {
      margin-bottom: $form-section-vertical-spacing;
    } @else {
      margin: 0 auto $form-section-vertical-spacing;
    }

    border-bottom: 2px solid $divider-regular-color;
  }

  .tab {
    cursor: pointer;
    padding-left: spacing(1);
    padding-right: spacing(1);
    padding-bottom: $tabbed-view-tab-padding;
    border-radius: 0;
    color: $text-secondary-color;
    text-align: center;
    min-width: $tab-min-width;

    transition: color 0.2s ease-out;

    @if ($tab-horizontal-spacing != false) {
      margin: 0 $tab-horizontal-spacing;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:hover {
      color: $text-secondary-color;
      opacity: 0.9;
    }

    &.selected {
      margin-bottom: -2px;
      padding-bottom: $tabbed-view-tab-padding - 2px;
      border-bottom: 4px solid $border-selected-color;
      color: $text-primary-color;
      font-weight: bold;
    }
  }

  .tab-content {
    height: 100%;
  }

  .contents-wrapper {
    display: block;
    @include fadeIn;

    box-sizing: border-box;
  }
}

@mixin loading-icon-for-button() {
  width: 24px;
  height: 24px;

  ::ng-deep {
    svg-icon {
      @include spinner-animation();
    }
  }
}

@mixin spinner-animation() {
  @keyframes uil-ring-anim {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  animation: uil-ring-anim 1.5s linear 0s infinite;
}

.loading-icon {
  ::ng-deep {
    svg-icon {
      @include spinner-animation();
    }
  }
}

@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin trunc-text-to-lines($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
